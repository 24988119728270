/**
 * ONBOARDING
 * --
 * Contains all the CSS that is unique to the onboarding pages.
 */

.outer {
  background: $white;
  min-height: 100vh;
}

.signup {
  margin-top: -90px;
  padding: 0;

  @include media-tablet() {
    margin-top: -75px;
  }

  &__checkbox {
    display: inline-block;
    margin: 0 !important;
    margin-right: 10px !important;
    position: relative;
    top: 1px;
  }
}

/**
 * Onboarding Container
 * --
 * The container that surrounds the elements of the Sign Up and Sign In pages.
 */

.onboarding-container {
  &__links {
    a {
      color: $color-secondary;
      font-size: 16px;
    }

    .col-md-6 {
      &:first-child {
        border-right: 1px solid $border-color-grey;
      }
    }
  }

  &__form {
    background: $white;
    box-shadow: 0 0 2px 0 $border-color-grey-dark;
    margin-left: -15px;
    margin-right: -15px;
    padding: 30px 0;
    width: calc(100% + 30px);

    @include media-tablet() {
      border-radius: 6px;
      padding: 30px 15px;
      width: 100%;
      margin: 0;
    }
  }

  &__item {
    margin-bottom: 40px;
  }
}

.onboarding {
  &__menu {
    margin: 20px 0;

    @include media-tablet() {
      margin: 0;
    }

    &-container {
      display: none;

      @include media-mobile() {
        display: block;
      }
    }

    .completed {
      color: $color-primary !important;
    }
  }
  &__skill-name,
  &__skill-remove {
    font-weight: normal;

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p {
      font-size: 0.8rem;
      margin-bottom: 10px;
      padding-top: 5px;
    }

    button {
      padding: 0;
    }
  }

  &__skill-input {
    align-items: center;
    display: flex;
    justify-content: space-between;

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &__skill-row {
    border-bottom: 1px solid $border-color-grey;
    padding: 15px 0;

    &:last-child {
      border: 0;
      padding-bottom: 0;
    }

    .form-control {
      border: none;
      color: $color-primary;
      font-size: 1.6rem;
      font-weight: normal;
      height: 70px;
      margin-bottom: 0;
      padding: 0 0 4px 20px;
      text-align: center;

      &:focus {
        background: $white;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      margin: 0;
      width: 20px;
    }
  }
}

.suggestion {
  &__items {
  }

  &__item {
    display: flex;
    margin-bottom: 20px;
  }

  &__image {
    display: flex;
    margin-right: 30px;

    &--avatar {
      border-radius: 50%;
      height: 60px;
      width: 60px;
    }
  }

  &__text {
    align-items: center;
    color: $color-secondary;
    display: flex;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

  &__button {
  }
}

/**
 * Onboarding Modals
 * --
 * The container that surrounds the elements of the Sign Up and Sign In modals.
 */
