/**
 * FONTS
 * --
 * Contains all of the CSS used to drag in custom fonts into the website. The
 * site currently only uses one custom web font - 'Gotham'. Below shows details
 * of how to pull the 3rd party service Typography.com Gotham font, and the
 * locally hosted Gotham font.
 */
// @import url("https://rsms.me/inter/inter.css");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html {
	// font-family: "Inter", sans-serif;
	font-family: "Be Vietnam Pro", sans-serif;
}

@supports (font-variation-settings: normal) {
	html {
		// font-family: "Inter var", sans-serif;
		font-family: "Be Vietnam Pro", sans-serif;
	}
}

@font-face {
	font-family: "Be Vietnam Pro", sans-serif;
	src: url("./../../../assets/BeVietnamPro-Medium.ttf") format("ttf");
	font-weight: normal;
	font-style: normal;
}
// @font-face {
// 	// font-family: "hk_groteskmedium";
// 	font-family: "Be Vietnam Pro", sans-serif;
// 	src: url("https://leo-static-assets.s3.amazonaws.com/fonts/hkgrotesk-medium-webfont.woff2")
// 			format("woff2"),
// 		url("https://leo-static-assets.s3.amazonaws.com/fonts/fonts/hkgrotesk-medium-webfont.woff")
// 			format("woff");
// 	font-weight: normal;
// 	font-style: normal;
// }
