.leo-pr {
	&-5 {
		padding-right: 5px;
	}
	&-10 {
		padding-right: 10px;
	}
	&-15 {
		padding-right: 15px;
	}
	&-20 {
		padding-right: 20px;
	}
	&-25 {
		padding-right: 25px;
	}
	&-30 {
		padding-right: 30px;
	}
	&-35 {
		padding-right: 35px;
	}
	&-40 {
		padding-right: 40px;
	}
	&-50 {
		padding-right: 50px;
	}
}
.leo-pl {
	&-5 {
		padding-left: 5px;
	}
	&-10 {
		padding-left: 10px;
	}
	&-15 {
		padding-left: 15px;
	}
	&-20 {
		padding-left: 20px;
	}
	&-25 {
		padding-left: 25px;
	}
	&-30 {
		padding-left: 30px;
	}
	&-35 {
		padding-left: 35px;
	}
	&-40 {
		padding-left: 40px;
	}
	&-50 {
		padding-left: 50px;
	}
}
.leo-pt {
	&-5 {
		padding-top: 5px;
	}
	&-10 {
		padding-top: 10px;
	}
	&-15 {
		padding-top: 15px;
	}
	&-20 {
		padding-top: 20px;
	}
	&-25 {
		padding-top: 25px;
	}
	&-30 {
		padding-top: 30px;
	}
	&-35 {
		padding-top: 35px;
	}
	&-40 {
		padding-top: 40px;
	}
	&-50 {
		padding-top: 50px;
	}
}
.leo-pb {
	&-5 {
		padding-bottom: 5px;
	}
	&-10 {
		padding-bottom: 10px;
	}
	&-15 {
		padding-bottom: 15px;
	}
	&-20 {
		padding-bottom: 20px;
	}
	&-25 {
		padding-bottom: 25px;
	}
	&-30 {
		padding-bottom: 30px;
	}
	&-35 {
		padding-bottom: 35px;
	}
	&-40 {
		padding-bottom: 40px;
	}
	&-50 {
		padding-bottom: 50px;
	}
}
