.leo-mr {
  &-5 {
    margin-right: 5px;
  }
  &-10 {
    margin-right: 10px;
  }
  &-15 {
    margin-right: 15px;
  }
  &-20 {
    margin-right: 20px;
  }
  &-25 {
    margin-right: 25px;
  }
  &-30 {
    margin-right: 30px;
  }
  &-35 {
    margin-right: 35px;
  }
  &-40 {
    margin-right: 40px;
  }
  &-50 {
    margin-right: 50px;
  }
}
.leo-ml {
  &-5 {
    margin-left: 5px;
  }
  &-10 {
    margin-left: 10px;
  }
  &-15 {
    margin-left: 15px;
  }
  &-20 {
    margin-left: 20px;
  }
  &-25 {
    margin-left: 25px;
  }
  &-30 {
    margin-left: 30px;
  }
  &-35 {
    margin-left: 35px;
  }
  &-40 {
    margin-left: 40px;
  }
  &-50 {
    margin-left: 50px;
  }
}
.leo-mt {
  &-5 {
    margin-top: 5px;
  }
  &-10 {
    margin-top: 10px;
  }
  &-15 {
    margin-top: 15px;
  }
  &-20 {
    margin-top: 20px;
  }
  &-25 {
    margin-top: 25px;
  }
  &-30 {
    margin-top: 30px;
  }
  &-35 {
    margin-top: 35px;
  }
  &-40 {
    margin-top: 40px;
  }
  &-50 {
    margin-top: 50px;
  }
}
.leo-mb {
  &-5 {
    margin-bottom: 5px;
  }
  &-10 {
    margin-bottom: 10px;
  }
  &-15 {
    margin-bottom: 15px;
  }
  &-20 {
    margin-bottom: 20px;
  }
  &-25 {
    margin-bottom: 25px;
  }
  &-30 {
    margin-bottom: 30px;
  }
  &-35 {
    margin-bottom: 35px;
  }
  &-40 {
    margin-bottom: 40px;
  }
  &-50 {
    margin-bottom: 50px;
  }
}
