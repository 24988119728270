.leo-width-full {
	width: 100% !important;
}
.leo-width-max {
	width: max-content;
}
.leo-width-min {
	width: min-content;
}
.leo-inline {
	display: inline !important;
}
.leo-block {
	display: block !important;
}
