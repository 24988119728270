/* .intercom-lightweight-app-launcher {
	width: 50px !important;
	height: 50px !important;
	background: #2a3744 !important;
	bottom: 15px !important;
	right: 10px !important;
}
.intercom-launcher-active {
	background: #2a3744 !important;
	width: 50px !important;
	height: 50px !important;
}
..intercom-namespace .intercom-181f4vn {
	background: #2a3744 !important;
	width: 50px !important;
	height: 50px !important;
}
.with-action-menu {
	right: 70px !important;
}
.intercom-lightweight-app-launcher-icon {
	width: 50px !important;
	height: 50px !important;

	svg {
		width: 20px !important;
	}
}
.intercom-lightweight-app-launcher-icon svg {
	width: 20px !important;
} */
.intercom-namespace .intercom-173rzil {
	bottom: 80px !important;
	right: 10px !important;
	height: calc(100% - 100px) !important;
}
