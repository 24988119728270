/**
 * PROFILE
 * --
 * Contains all the CSS that is unique to the profile pages.
 */

/**
 * Profile Container
 * --
 * The container that surrounds the elements of the Profile pages.
 */

.profile-settings,
.onboarding,
.search {
  padding-left: 0;
  padding-right: 0;

  @include media-tablet() {
    padding-left: 15px;
    padding-right: 15px;
  }

  &__container {
    background: $white;
    box-shadow: 0 0 2px 0 $border-color-grey-dark;
    padding: 20px 0;
    width: 100%;

    @include media-tablet() {
      border-radius: 6px;
      margin: 0;
      padding: 40px 25px;
    }
  }

  &__header {
    position: absolute;
    left: 0;
    top: 0;
  }

  &__menu {
    @include media-mobile() {
      margin-right: -30px;
    }

    &-container {
      padding-top: 10px;
      width: calc(100% + 30px);

      @include media-tablet() {
        padding: 0;
        width: 100%;
      }

      li {
        display: inline-block;
        padding: 0 15px;

        @include media-desktop() {
          display: block;
          padding: 0;
        }
      }
    }
  }

  &__video {
    align-items: center;
    background: $grey-light;
    display: flex;
    height: 100%;
    justify-content: center;
    margin-bottom: 30px;
    max-width: 320px;
    overflow: hidden;
    position: relative;

    @include media-tablet() {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      overflow: hidden;
    }

    .media-controls {
      background: transparent;
      justify-content: center;
      top: 0;
    }

    .media-control-group {
      background: rgba($white, 0.2);
      border-radius: 8px;
      height: 52px;
      width: 84px;

      button {
        height: 100%;
        width: 100%;
      }
    }
  }

  .settings {
    &__heading {
      color: $color-secondary;
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      margin: 0;
    }

    &__row {
      padding: 2px 0 12px;
    }

    &__col {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &__skills {
    }
  }

  .settings-video {
    .profile-settings__video {
      height: 100px;
    }

    input {
      align-items: center;
      border: 2px dashed #a8abb1;
      border-radius: 0;
      color: #ffffff;
      cursor: pointer;
      display: -ms-flexbox;
      display: flex;
      float: left;
      height: 100px;
      -ms-flex-pack: center;
      justify-content: center;
      margin-right: 30px;
      opacity: 0.5;
      width: 100px;
    }

    svg {
      left: 55px;
      position: absolute;
      top: 72px;
    }

    p {
      color: $grey-medium;
      font-size: 12px;
      line-height: 18px;
    }
  }

  .settings-cover {
    &:hover {
      .settings-cover__button {
        opacity: 1;
      }
    }

    &__image {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      background: center center no-repeat $color-secondary;
      background-size: cover;
      border-radius: 6px;
      box-sizing: border-box;
      display: inline-block;
      height: 180px;
      overflow: hidden;
      padding: 180px 0 0 0;
      width: 100%;

      &:before {
        background-color: rgba(43, 43, 43, 0.2);
        border-radius: 6px;
        bottom: 6px;
        content: "";
        display: block;
        left: 15px;
        position: absolute;
        right: 15px;
        top: 0;
        transition: all 0.2s ease-in-out;
      }

      &:hover {
        &:before {
          background-color: rgba(43, 43, 43, 0.5);
          cursor: pointer;
        }
      }
    }

    &__button {
      color: $white;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 80px;
      transition: all 0.2s ease-in-out;
      width: calc(100% - 30px);
      z-index: 2;
    }
  }

  .settings-buttons {
    margin-top: 75px;
    text-align: center;

    .button {
      height: 40px;
      line-height: 20px;
    }

    .button--white {
      color: $grey-medium;
      font-weight: 400;
    }
  }

  .settings-add {
    &__button {
      background: $grey-dark;
      margin-right: 20px;
    }
  }

  .settings-avatar {
    background: center no-repeat $white;
    border-radius: 50%;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
    height: 100px;
    left: calc(50% - 50px);
    object-fit: cover;
    position: absolute;
    top: -55px;
    width: 100px;

    &__input {
      display: none;
    }

    &__button {
      cursor: pointer;
      display: inline-block;
      margin-right: 20px;
      width: 140px;
    }
  }

  .experience {
    &__item {
      padding: 20px 0;

      &--checkbox {
        color: $color-secondary;
        font-size: 13px;
        margin-bottom: 30px;
      }

      &-search {
        min-height: 140px;
        overflow-y: auto;
        padding: 0;
        position: absolute;
        top: 75px;
        width: 285px;
        z-index: 900;

        span {
          border-bottom: 1px solid $border-color-grey;
          padding: 10px 15px;

          &:hover {
            background: #f6f6f6;
            cursor: pointer;
          }
        }
      }
    }

    &__delete {
      color: $color-red;

      &:hover {
        color: darken($color-red, 10%);
        text-decoration: none;
      }
    }
  }

  .location-search-input {
    position: relative;
  }

  .admins {
    &-input {
      display: inline-block;
      width: 80%;
    }

    &__item {
      align-items: center;
      border-bottom: 1px solid $border-color-grey;
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
      position: relative;

      &:last-child {
        border-bottom: 0;
      }
    }

    &__avatar {
      border-radius: 50%;
      display: inline-block;
      height: 60px;
      margin-right: 30px;
      min-width: 60px;
      overflow: hidden;
      width: 60px;
    }

    &__image {
      background: $white;
      height: 60px;
      object-fit: cover;
      width: 60px;
    }

    &-details {
      align-items: center;
      display: flex;
      margin-right: 30px;

      &__name {
        color: $color-secondary;
        display: inline-block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 3px;
      }

      &__icon {
        display: inline-block;
        margin-left: 5px;
      }

      &__title {
        font-size: 14px;
      }
    }

    &-actions {
      align-items: center;
      display: flex;

      .owner {
        margin-right: 46px;
      }
    }

    &-options {
      align-items: center;
      display: flex;

      .dropdown-menu {
        top: 70px;
      }
    }
  }
}

.company {
  &-header {
    margin-bottom: 20px;

    &__cover {
      overflow: visible;

      &:before {
        background: transparent;
      }

      .button--settings {
        display: block;
        position: absolute;
        right: 20px;
        top: 20px;

        @include media-tablet() {
          bottom: 20px;
          left: auto;
          top: auto;
        }
      }
    }

    &__video {
      align-items: center;
      background: $grey-light;
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: hidden;
      width: 100%;

      @include media-tablet() {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        overflow: hidden;
      }

      .media,
      video {
        height: 260px;
        max-width: none;

        @include media-tablet() {
          height: auto;
          min-width: 100%;
        }

        &-player {
          max-height: 100%;
        }
      }

      .media-controls {
        align-items: flex-start;
        background: transparent;
        justify-content: flex-end;
        top: 0;
      }

      .media-control-group {
        background: rgba($white, 0.2);
        border-radius: 8px;
        height: 52px;
        width: 84px;

        button {
          height: 100%;
          width: 100%;
        }
      }
    }

    &__details {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 70px 0 20px;
      position: relative;

      @include media-tablet() {
        justify-content: flex-start;
        padding: 0;
      }
    }

    &__info {
      min-height: 100px;
      padding: 15px 20px;
    }
  }

  &-info {
    color: $grey-dark;

    @include media-tablet() {
      text-align: left;
    }

    &__name {
      display: inline-block;
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 8px;
      margin-right: 10px;
    }

    &__verified {
      display: inline-block;
      position: relative;
      top: 2px;
    }

    &__title {
      color: $grey-medium;
      font-size: 13px;
      line-height: 1.5;
      margin-bottom: 10px;

      @include media-tablet() {
        height: auto;
        margin-bottom: 5px;
      }
    }
  }

  &-avatar {
    background: $white;
    border: 3px solid $white;
    border-radius: 50%;
    bottom: -60px;
    height: 106px;
    position: absolute;
    width: 106px;

    @include media-tablet() {
      bottom: 20px;
      left: 20px;
    }

    &__image {
      background: $white;
      border-radius: 50%;
      height: 100px;
      object-fit: cover;
      width: 100px;
    }
  }
}

.autocomplete-dropdown-container {
  background: $white;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  top: -31px;

  .suggestion-item,
  .suggestion-item:hover,
  .suggestion-item--active {
    border-bottom: 1px solid $border-color-grey;
    padding: 12px;
  }
}

.location-search-input[aria-expanded="true"]
  + .autocomplete-dropdown-container {
  border: 1px solid $border-color-grey-dark;
}

$bg-color: #f3f3f3;
$fg-color: #00cba7;
$speed: 500ms;
$percent: 80;

.chart {
  &.skills-item__rating {
    width: 32px;
  }

  svg {
    display: block;
  }
}

$number: 0;
@while $number <= 10 {
  $percent: $number * 10;
  .chart.percent-#{$percent} .circle-fg {
    stroke-dasharray: $percent 100;
    animation: up-#{$percent} $speed ease-out forwards;
  }
  .chart.percent-#{$percent}:hover .circle-fg {
    animation: down-#{$percent} $speed ease-out forwards;
  }
  @keyframes up-#{$percent} {
    0% {
      stroke-dasharray: 0 100;
    }
    100% {
      stroke-dasharray: $percent 100;
    }
  }
  @keyframes down-#{$percent} {
    0% {
      stroke-dasharray: $percent 100;
    }
    100% {
      stroke-dasharray: 0 100;
    }
  }
  $number: $number + 1;
}
