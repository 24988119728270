.leo-pointer {
	cursor: pointer;
}
.leo-link {
	text-decoration: none !important;
	color: inherit !important;

	&:hover {
		:hover {
			text-decoration: none !important;
			color: inherit !important;
		}
	}
}
.leo-unbiased {
	filter: blur(1.5px);

	&-strong {
		filter: blur(7px);
	}
}
