.leo-fade-in {
	animation: fadein 500ms;

	@keyframes fadein {
		from {
			visibility: hidden;
			opacity: 0;
		}
		to {
			visibility: visible;
			opacity: 1;
		}
	}
}
.leo-fade-out {
	animation: fadeout 500ms;

	@keyframes fadeout {
		from {
			visibility: visible;
			opacity: 1;
		}
		to {
			visibility: hidden;
			opacity: 0;
		}
	}
}
