.leo-ellipsis {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.leo-text-center {
	text-align: center;
}
.leo-text-end {
	text-align: end;
}
.leo-text-start {
	text-align: start;
}
